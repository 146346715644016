<template>
  <keep-alive>
    <router-view v-if="$route.meta.subPage"/>
    <a-card :bordered="false" class="sea-order">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="GLOBAL.queryRowGutter">
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="业务类型">
                <a-select showSearch style="width: 100%"
                          allowClear v-model="queryParam.type"
                          @change="$refs.table.refresh(true)"
                >
                  <a-select-option :key="0">保税仓储</a-select-option>
                  <a-select-option :key="1">海运进口</a-select-option>
                  <a-select-option :key="2">海运出口</a-select-option>
                  <a-select-option :key="3">空运进口</a-select-option>
                  <a-select-option :key="4">空运出口</a-select-option>
                  <a-select-option :key="11">进口内装</a-select-option>
                  <a-select-option :key="12">出口内装</a-select-option>
                  <a-select-option :key="13">其他</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="订单编号">
                <a-input v-model.trim="queryParam['serial_num']"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="提单号">
                <a-input v-model.trim="queryParam['delivery_num']"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="主单号">
                <a-input v-model.trim="queryParam['order_num']"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="委托客户">
                <a-select
                  showSearch
                  label-in-value
                  allowClear
                  placeholder="请选择委托客户"
                  style="width: 100%"
                  :value="customerInfo"
                  :filter-option="false"
                  :not-found-content="fetching ? undefined : null"
                  @search="getDepartmentCustomer"
                  @change="changeCustomerInfo"
                >
                  <template v-if="fetching" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                  <a-select-option v-for="op in agencyOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="箱号">
                <a-input v-model.trim="queryParam['box_info']"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="清关类型">
                <a-input v-model.trim="queryParam['clear_type']"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="客户业务编号">
                <a-input v-model.trim="queryParam['customer_num']"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="进/出口报关单号">
                <a-input v-model.trim="queryParam['declare_num']"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="进境备案清单号">
                <a-input v-model.trim="queryParam['clear_record_num']"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="报价编号">
                <a-input v-model.trim="queryParam['quota_num']"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-model-item label="订单类型">
                <a-select
                  allowClear
                  placeholder="请选择订单类型"
                  style="width: 100%"
                  v-model="queryParam['order_kind']"
                  @change="$refs.table.refresh(true)"
                >
                  <a-select-option v-for="op in orderKindOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="创建时间">
                <a-range-picker @change="onDateChange" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-row :gutter="24">
          <a-col :span="20">
            <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
            <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
          </a-col>
          <a-col :span="4">
            <a-popover placement="bottomRight">
              <template slot="content" v-if="noChargeOrPayOrderData.length > 0">
                <p v-for="(op, index) in noChargeOrPayOrderData" :key="index">
                  <span style="color: green; font-size: 14px;">{{ op.serial_num }}</span> -
                  <span style="color: orange; font-size: 14px;" v-if="op.no_charge_msg">{{ op.no_charge_msg }} / </span>
                  <span style="color: red; font-size: 14px;" v-if="op.no_pay_msg">{{ op.no_pay_msg }}</span>
                </p>
              </template>
              <template slot="content" v-else>
                <span>暂无</span>
              </template>
              <template slot="title">
                <span>未收未付订单</span>
              </template>
              <a-button :type="noChargeOrPayOrderData.length > 0 ? 'danger' : null" icon="alert">
                未收未付订单{{ noChargeOrPayOrderData.length }}条
              </a-button>
            </a-popover>
          </a-col>
        </a-row>
      </div>

      <s-table
        ref="table"
        size="small"
        :rowKey="(r) => {return r.order.id}"
        :filterColumn="true"
        :columns="columns"
        :data="loadData"
        :scroll="{ x: 'max-content' }"
        @dblclick="handleEdit">
        <!-- :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }" -->
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <template slot="orderFileSlot" slot-scope="text">
          <div v-for="op in text" :key="op">
            <a :href="op.file_url" target="downloadFile" v-if="op.file_url">
              <a-icon type="file-image" />{{op.original_file_name}}
            </a>
            <a-icon type="delete" @click="handleDeleteFile(op)"/>
          </div>
        </template>
        <span slot="action" slot-scope="text, record, index">
          <template>
            <a @click="handleEdit(record, index)">编辑</a>
            <a-divider type="vertical" />
            <a @click="handleCopy(record)">复制</a>
            <a-divider type="vertical" />
            <a @click="handleDelete('row', record)">删除</a>
            <a-divider type="vertical" />
            <a-upload
              :beforeUpload="beforeUpload"
              :customRequest="e => handleUpload(e, record)"
              :showUploadList="false"
            >
              <a-button>上传文件（拖拉）</a-button>
            </a-upload>
          </template>
        </span>
      </s-table>
    </a-card>
  </keep-alive>
</template>
<script>
import { STable } from '@/components'
import moment from 'moment'
import { getFirstLetter } from '@/utils/util'
import { getCustomer } from '@/api/common'
import {
  pageListMain,
  deleteItmsOrderInfo,
  queryItmsOrderConTenDayNotChargeOrPayOrderList,
  uploadFileByOrder,
  deleteOrderFile
} from '@/api/itms'
import debounce from 'lodash/debounce';
export default {
  components: {
    STable
  },
  data() {
    this.lastFetchId = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    return {
      queryParam: {},
      agencyOps: [],
      customerInfo: undefined,
      fetching: false,
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 50,
          align: 'center'
        },
        {
          title: '订单编号',
          dataIndex: 'order.serial_num'
        },
        {
          title: '业务类型',
          dataIndex: 'order.business_type',
          width: 100,
          customRender: (text) => {
            switch (text) {
              case 0:
                return '保税仓储'
              case 1:
                return '海运进口'
              case 2:
                return '海运出口'
              case 3:
                return '空运进口'
              case 4:
                return '空运出口'
              case 11:
                return '进口内装'
              case 12:
                return '出口内装'
              case 13:
                return '其他'
            }
          }
        },
        {
          title: '报价编号',
          dataIndex: 'order.quota_num'
        },
        {
          title: '委托客户',
          dataIndex: 'order.agency_name',
          width: 160
        },
        {
          title: '客户业务编号',
          dataIndex: 'order.customer_num'
        },
        {
          title: '应收费用状态',
          dataIndex: 'order.charge_status',
          width: 120,
          customRender: (text) => {
            return this.GLOBAL.feeStatusMaps[text] ? this.GLOBAL.feeStatusMaps[text].name : ''
          }
        },
        {
          title: '应付费用状态',
          dataIndex: 'order.pay_status',
          customRender: (text) => {
            return this.GLOBAL.feeStatusMaps[text] ? this.GLOBAL.feeStatusMaps[text].name : ''
          }
        },
        {
          title: '调整',
          dataIndex: 'order.has_adjust',
          customRender: (text) => {
            return text ? '是' : ''
          }
        },
        {
          title: '创建时间',
          dataIndex: 'order.created_at',
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '文件',
          dataIndex: 'order.order_file_list',
          scopedSlots: { customRender: 'orderFileSlot' }
        },
        {
          title: '订舱代理',
          dataIndex: 'order.agent_name'
        },
        {
          title: '主单号',
          dataIndex: 'order.order_num'
        },
        {
          title: '船名',
          dataIndex: 'content.ship_name'
        },
        {
          title: '船公司',
          dataIndex: 'order.trans_company'
        },
        {
          title: '起运港',
          dataIndex: 'content.departure'
        },
        {
          title: '目的港',
          dataIndex: 'content.destination'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 200,
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      selectedRowKeys: [],
      currentData: null,
      orderKindOps: [
        { name: '国贸', value: 1 },
        { name: '奥货', value: 2 },
        { name: '订舱', value: 3 }
      ],
      loadData: parameter => {
        return pageListMain(Object.assign(parameter, this.queryParam)).then(res => {
          return res
        })
      },
      noChargeOrPayOrderData: []
    }
  },
  watch: {
    $route: function(newRoute) {
      if (newRoute.name === 'SeaOrder' && this.queryParam) {
        newRoute.meta.title = '海运订单管理'
        if (newRoute.query.isEdit && newRoute.params) {
          Object.assign(this.currentData, newRoute.params)
        } else {
          this.$nextTick(_ => {
            this.$refs.table.refresh()
          })
        }
      }
    }
  },
  mounted() {
    this.loadTenDayNoChargeOrPayOrderData()
  },
  methods: {
    moment,
    // 加载10天前已填写发票或出纳 还没收款或付款的记录
    loadTenDayNoChargeOrPayOrderData() {
      // 查询国贸、订舱类型的记录
      const reqParam = {
        'orderkinds': [1, 3]
      }
      queryItmsOrderConTenDayNotChargeOrPayOrderList(reqParam).then(res => {
        this.noChargeOrPayOrderData = res
      })
    },
    handleCreate() {
      this.$router.push({
        name: 'SeaOrderCUR',
        params: {
          id: 'create'
        }
      })
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.agencyOps = [];
      this.fetching = true;
      getCustomer({
        department: 2,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.agencyOps = res
        this.fetching = false;
      })
    },
    changeCustomerInfo(value) {
      if (value) {
        this.customerInfo = value
        this.queryParam['auth_agency'] = value.key
      } else {
        this.customerInfo = undefined
        this.queryParam['auth_agency'] = null
      }
      this.agencyOps = []
      this.fetching = false
      this.$refs.table.refresh(true)
    },
    handleEdit(record) {
      this.currentData = record
      this.$router.push({
        name: 'SeaOrderCUR',
        params: {
          id: record.order.id,
          ...record
        }
      })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
          getFirstLetter(option.componentOptions.children[0].text).toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    handleCopy(record) { // copy
      this.$router.push({
        name: 'SeaOrderCUR',
        params: {
          id: record.order.id
        },
        query: {
          isCopy: true
        }
      })
    },
    handleDelete(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          deleteItmsOrderInfo(record.order).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.$refs.table.refresh(true)
          })
        }
      })
    },
    onDateChange(date, dateString) {
      if (dateString[0]) {
        this.queryParam['begin_time'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss');
        this.queryParam['end_time'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss');
      } else {
        this.queryParam['begin_time'] = null;
        this.queryParam['end_time'] = null;
      }
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    beforeUpload(file) {
      const fileType = ['jpg', 'jpeg', 'png', 'doc', 'docx', 'pdf', 'PDF', 'xls', 'xlsx', 'zip', 'rar']
      const suffix = file.name.split('.')[1]
      if (fileType.indexOf(suffix) === -1) {
        this.$notification['error']({
          message: '提示',
          description: '只能上传word文件、pdf文件、excel文件、图片文件、压缩文件'
        })
        return false
      }
      if (file.size / 1024 / 1024 > 10) {
        this.$notification['error']({
          message: '提示',
          description: '上传文件不能超过10MB'
        })
        return false
      }
    },
    handleUpload(param, record) {
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('id', record.order.id)
      uploadFileByOrder(formData).then(res => {
        this.$notification['success']({
          message: '提示',
          description: '上传成功'
        })
        this.$refs.table.refresh(true)
      })
    },
    handleDeleteFile(fileInfo) {
      this.$confirm({
        title: '提示',
        content: '确定要删除' + fileInfo.original_file_name + '吗?',
        onOk: () => {
          deleteOrderFile({ 'id': fileInfo.id }).then(res => {
            this.$notification['success']({
              message: '提示',
              description: '删除文件成功'
            })
            this.$refs.table.refresh(true)
          })
        }
      })
    }
  }
}
</script>

<style>
  .sea-order .table-page-search-wrapper .ant-form-item-label{
    min-width: 126px!important;
  }
</style>
